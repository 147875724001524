<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="page__body__header">
        <div class="row mb-5 align-items-end justify-content-between">
          <div class="col-5">
            <label class="form__label">Custom Field Set</label>
            <select
              id="fieldSetsDropdown"
              class="select select--lg"
              v-model="fieldSetKey"
              @change="fieldSetSelectChange($event)"
            >
              <option value="null" disabled selected>Select your field</option>
              <option
                v-for="item in fieldSets"
                v-bind:key="item.customFieldSetKey"
                v-bind:value="item.customFieldSetKey"
              >
                {{ item.customFieldSetName }}
              </option>
            </select>
          </div>
          <div class="col-7">
            <div class="row align-items-center justify-content-between">
              <div class="col-4">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#addModal"
                  class="
                    w-100
                    button
                    page__body__header__button
                    button--grey
                    text--capital
                  "
                  v-if="userCanCreateCustomField"
                >
                  <span class="d-inline-flex mr-2">add new</span>
                  <ion-icon name="add-outline" class="text--medium"></ion-icon>
                </button>
              </div>
              <div class="col-4" v-if="userCanEditCustomField">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#editFieldSetModal"
                  @click="editFieldSet(fieldSet.customFieldSetKey)"
                  v-if="fieldSet"
                  class="
                    w-100
                    button
                    page__body__header__button
                    button--grey
                    text--capital
                  "
                >
                  <span class="d-inline-flex mr-2">edit</span>
                  <ion-icon
                    name="pencil-outline"
                    class="text--regular"
                  ></ion-icon>
                </button>
              </div>
              <div class="col-4" v-if="userCanDeleteCustomField">
                <button
                  data-toggle="modal"
                  data-target="#deleteFieldSetModal"
                  @click="
                    openDeleteConfirmation(
                      fieldSet.customFieldSetKey,
                      'fieldSet'
                    )
                  "
                  type="button"
                  v-if="fieldSet"
                  class="
                    button
                    w-100
                    page__body__header__button
                    button--grey
                    text--capital
                  "
                >
                  <span class="d-inline-flex mr-2">delete</span>
                  <ion-icon
                    name="trash-bin-outline"
                    class="text--regular"
                  ></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <!-- <div class="align-self-center dropdown">
              <button
                class="button button--grey text--capital text--small d-flex align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="dropdown__icon--left">
                  <img src="../../../assets/img/filter.svg" alt="" srcset="" />
                </span>
                <span class="filter_text"> filter for </span>
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item text--capital"
                  onclick="toggleFilter(this)"
                  >none</a
                >
                <a
                  class="dropdown-item text--capital"
                  onclick="toggleFilter(this)"
                  >groups</a
                >
              </div>
            </div> -->
          </div>
          <div class="col-6" v-if="userCanCreateCustomField">
            <button
              type="button"
              data-toggle="modal"
              data-target="#addFieldModal"
              @click="clearFieldForm()"
              v-if="fieldSet"
              class="
                button
                page__body__header__button
                button--black
                text--capital
                float-right
              "
            >
              <span class="d-inline-flex mr-2">add custom field</span>
              <ion-icon name="add-outline" class="text--medium"></ion-icon>
            </button>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-center justify-content-center p5"
        v-if="isLoading"
      >
        <div
          class="spinner-border"
          style="width: 3rem; height: 3rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <span class="text--black text--600 text--medium d-inline-flex ml-4"
          >Please wait</span
        >
      </div>
      <table class="customtable w-100" v-show="!isLoading">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20%"
            >
              field name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20%"
            >
              ID
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              type
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              values
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              active
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in fields" v-bind:key="item.customFieldKey">
            <td style="padding-right: 20px !important;">
              <span class="table__body__text table__text text--capital" style="word-break: break-word;">
                {{ item.customFieldName }}</span
              >
            </td>
            <td style="padding-right: 20px !important;">
              <span class="table__body__text table__text text--capital" style="word-break: break-all;">{{
                item.uniqueUserDefinedId
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                item.dataType
              }}</span>
            </td>
            <td v-if="item.dataType.toLowerCase() === 'selection'">
              <span
                class="table__body__text table__text text--capital"
                v-for="val in item.customFieldSelections"
                v-bind:key="val"
              >
                <!-- <p>
                  <label>Selection {{ index + 1 }}</label>
                  <span style="display: block;">Score: {{ val.score }}</span>
                  <span style="display: block;">Value: {{ val.value }}</span>
                </p> -->
                <p>
                  <span>{{ val.value }}</span> 
                  <span v-if="val.score > 0"> ({{ val.score }})</span>
                </p>
              </span>
            </td>
            <td v-else>
              <span
                class="table__body__text table__text text--capital"
                v-for="val in item.customFieldSelections"
                v-bind:key="val"
              >
                {{ val }}
              </span>
            </td>
            <td>
              <span
                v-if="item.customFieldStatus == 'Activated'"
                class="
                  table__body__text table__text
                  text--capital text--success
                "
                >{{ item.customFieldStatus }}</span
              >
              <span
                v-else
                class="table__body__text table__text text--capital text--danger"
                >{{ item.customFieldStatus }}</span
              >
            </td>
            <td style="padding-right: 0px !important;">
              <div class="align-self-center dropdown">
                <button
                  class="
                    button button--grey
                    text--capital text--small
                    d-flex
                    align-items-center
                  "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    href="#"
                    data-toggle="modal"
                    data-target="#editFieldModal"
                    type="button"
                    @click="editField(item.customFieldKey)"
                    v-if="userCanDeleteCustomField"
                    >edit</a
                  >
                  <a
                    @click="
                      openDeleteConfirmation(item.customFieldKey, 'field')
                    "
                    type="button"
                    class="dropdown-item text--capital"
                    href="#"
                    data-toggle="modal"
                    data-target="#deleteFieldModal"
                    v-if="userCanDeleteCustomField"
                    >delete</a
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="pagination">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing <span class="d-inline-block text--primary">1</span> to
            <span class="d-inline-block text--primary">10</span> of
            <span>1200</span>
          </div>
          <div
            class="pagination__buttons d-flex align-items-center justify-content-between"
          >
            <button class="button button--sm text--regular">
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              class="button text--regular button--primary text--white text-white button--sm ml-2"
            >
              1
            </button>
            <button class="button text--regular button--sm ml-2">2</button>
            <button class="button text--regular button--sm ml-2">3</button>
            <button class="button text--regular button--sm ml-2">4</button>
            <button class="button text--regular button--sm ml-2">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div> -->
    </div>
    <!-- Modal section starts here -->
    <div
      class="modal modal__right"
      id="addModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="modal"
                id="fieldSetFormCloseButton"
                class="modal__close modal__close--black"
                aria-label="Close"
                @click="clearFieldSetForm"
                :disabled="isSaving"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Create New Custom Fields Sets
            </h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveFieldSetForm">
              <div class="form__item">
                <label class="form__label">Name</label>
                <input
                  type="text"
                  v-model="fieldSetForm.customFieldSetName"
                  class="input form__input form__input--lg"
                  placeholder="Custom Fields Sets Name"
                  v-bind:class="{
                    'input-required': fieldSetFormErr.customFieldSetName,
                  }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">ID</label>
                <input
                  v-model="fieldSetForm.customFieldSetId"
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Custom Fields Sets ID"
                  v-bind:class="{
                    'input-required': fieldSetFormErr.customFieldSetId,
                  }"
                />
              </div>
              <!-- <div class="form__item">
                <label class="form__label"
                  >Select Type
                  <a
                    class="text--regular text--grey ml-2"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Standard shows each field from a set only one time and grouped sets can be added multiple times"
                    ><i class="bi bi-info-circle-fill"></i></a
                ></label>
                <select
                  class="select select--lg"
                  v-model="fieldSetForm.usage"
                  v-bind:class="{
                    'input-required': fieldSetFormErr.usage,
                  }"
                >
                  <option value="null" disabled selected>Select Type</option>
                  <option value="Standard">Standard</option>
                  <option value="Grouped">Grouped</option>
                </select>
              </div> -->
              <div class="form__item">
                <label
                  class="form__label text--regular-alt text--bold text--black"
                  >notes</label
                >
                <textarea
                  v-model="fieldSetForm.customFieldNotes"
                  name=""
                  cols="5"
                  rows="5"
                  class="input form__input"
                ></textarea>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="!isSaving"
                  >
                    save changes
                  </button>
                  <!-- loader button -->
                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      save-changes
                    "
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    v-if="!isSaving"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal modal__right"
      id="editFieldSetModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="modal"
                id="fieldSetEditFormCloseButton"
                class="modal__close modal__close--black"
                aria-label="Close"
                @click="clearFieldSetForm"
                :disabled="isSaving"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Edit Custom Fields Sets
            </h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveFieldSetEditForm">
              <div class="form__item">
                <label class="form__label">Name</label>
                <input
                  type="text"
                  v-model="fieldSetForm.customFieldSetName"
                  class="input form__input form__input--lg"
                  placeholder="Custom Fields Sets Name"
                  v-bind:class="{
                    'input-required': fieldSetFormErr.customFieldSetName,
                  }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">ID</label>
                <input
                  v-model="fieldSetForm.customFieldSetId"
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Custom Fields Sets ID"
                  v-bind:class="{
                    'input-required': fieldSetFormErr.customFieldSetId,
                  }"
                />
              </div>
              <div class="form__item">
                <label class="form__label"
                  >Select Type
                  <a
                    class="text--regular text--grey ml-2"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Standard shows each field from a set only one time and grouped sets can be added multiple times"
                    ><i class="bi bi-info-circle-fill"></i></a
                ></label>
                <select
                  class="select select--lg"
                  v-model="fieldSetForm.usage"
                  v-bind:class="{
                    'input-required': fieldSetFormErr.usage,
                  }"
                >
                  <option value="null" disabled selected>Select Type</option>
                  <option value="Standard">Standard</option>
                  <option value="Grouped">Grouped</option>
                </select>
              </div>
              <div class="form__item">
                <label
                  class="form__label text--regular-alt text--bold text--black"
                  >notes</label
                >
                <textarea
                  v-model="fieldSetForm.customFieldNotes"
                  name=""
                  cols="5"
                  rows="5"
                  class="input form__input"
                ></textarea>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="!isSaving"
                  >
                    save changes
                  </button>
                  <!-- loader button -->
                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      save-changes
                    "
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    v-if="!isSaving"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Ends here -->
    <div
      class="modal modal__confirm"
      id="deleteFieldSetModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                id="deleteFieldSetCloseButton"
                data-dismiss="modal"
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Field Set
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to delete this Fieldset
              </h5>
              <p class="form__label">
                when you delete this field set. you cant reactivate it back on
                this table
              </p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="modal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                >
                  cancel
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="
                    button
                    form__button form__button--lg
                    w-25
                    modal__confirm__button modal__confirm__button--delete
                  "
                  v-if="isDeleting"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  v-if="!isDeleting"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="deleteFieldSetByKey(fieldSetDeleteKey)"
                >
                  delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="addFieldModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="modal"
                id="fieldFormCloseButton"
                class="modal__close modal__close--black"
                aria-label="Close"
                @click="clearFieldForm"
                :disabled="isSaving"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Create Centres Custom Field
            </h5>
          </div>
          <div class="modal__body">
            <form class="form">
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Name</label>
                    <input
                      type="text"
                      v-model="fieldForm.customFieldName"
                      class="input form__input form__input--lg"
                      placeholder="Custom Field Name"
                      v-bind:class="{
                        'input-required': fieldFormErr.customFieldName,
                      }"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">ID</label>
                    <input
                      v-model="fieldForm.uniqueUserDefinedId"
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Custom Field ID"
                      v-bind:class="{
                        'input-required': fieldFormErr.uniqueUserDefinedId,
                      }"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Custom Field Set Type</label>
                    <input
                      v-model="fieldForm.customFieldType"
                      disabled
                      type="text"
                      class="input form__input form__input--lg"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Custom Field Set</label>
                    <select
                      class="select select--lg"
                      v-model="fieldForm.customFieldSetKey"
                      v-bind:class="{
                        'input-required': fieldFormErr.customFieldSetKey,
                      }"
                    >
                      <option value="null" disabled selected>
                        Select field set
                      </option>
                      <option
                        v-for="data in fieldFormFieldSets"
                        v-bind:key="data.customFieldSetKey"
                        v-bind:value="data.customFieldSetKey"
                      >
                        {{ data.customFieldSetName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Status</label>
                    <select
                      class="select select--lg"
                      v-model="fieldForm.customFieldStatus"
                      v-bind:class="{
                        'input-required': fieldFormErr.customFieldStatus,
                      }"
                    >
                      <option value="null" disabled selected>
                        Select field status
                      </option>
                      <option value="Activated">Activated</option>
                      <option value="Deactivated">Deactivated</option>
                    </select>
                  </div>
                </div>
              </div>

              <ul id="accordion" class="mb5">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#display"
                    aria-expanded="false"
                    aria-controls="ccgeneral"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Display
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="display" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="row">
                        <div
                          class="col-6"
                          v-bind:class="{
                            'col-12': fieldForm.dataType != 'FreeText',
                            'col-6': fieldForm.dataType == 'FreeText',
                          }"
                        >
                          <div class="form__item">
                            <label class="form__label">Type</label>
                            <select
                              class="select select--lg"
                              v-model="fieldForm.dataType"
                              v-bind:class="{
                                'input-required': fieldFormErr.dataType,
                              }"
                            >
                              <option value="null" disabled selected>
                                Select data type
                              </option>
                              <option value="FreeText">Free Text</option>
                              <option value="Selection">Selection</option>
                              <option value="Number">Number</option>
                              <option value="Checkbox">Checkbox</option>
                              <option value="Date">Date</option>
                              <option value="CustomerLink">
                                Customer Link
                              </option>
                              <option value="GroupLink">Group Link</option>
                              <option value="UserLink">User Link</option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="col-6"
                          v-if="fieldForm.dataType == 'FreeText'"
                        >
                          <div class="form__item">
                            <label class="form__label"
                              >Format<a
                                class="text--regular text--grey ml-2"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Custom Fields are standardized with a template using placeholders of '#' for numbers, '@' for letters or '$' for letter or number"
                                ><i class="bi bi-info-circle-fill"></i></a
                            ></label>
                            <input
                              v-model="fieldForm.validationPattern"
                              type="text"
                              class="input form__input form__input--lg"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="form__item">
                        <div>
                          <div class="mt-2 form__item form__item--label">
                            <label
                              for="users"
                              class="
                                has-checkbox
                                form__checkbox
                                text--dark text--capital
                              "
                              >Long Field
                              <input type="checkbox" id="users" class="form-" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        class="form__item"
                        v-if="fieldForm.dataType == 'FreeText'"
                      >
                        <div class="mt-2 form__item form__item--label">
                          <label
                            for="val"
                            class="
                              has-checkbox
                              form__checkbox
                              text--dark text--capital
                            "
                            >Unique Value
                            <input
                              type="checkbox"
                              v-model="fieldForm.isUnique"
                              id="val"
                              class="form-"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div
                        class="selection-option"
                        v-if="fieldForm.dataType == 'Selection'"
                      >
                        <label class="form__label">Options</label>
                        <div class="input form__input mb-3 options-box">
                          <p
                            v-for="(lab, index) in fieldFormSelectionOptions"
                            :key="index"
                          >
                            {{ lab.label }}
                            <span v-show="lab.score">({{ lab.score }})</span>
                            <span>
                              <ion-icon
                                @click="editSelectionOption(index, lab)"
                                name="pencil-outline"
                                class="pointer text--regular ml-5"
                              ></ion-icon>
                              <ion-icon
                                @click="removeFromSelectionOption(index)"
                                name="trash-bin-outline"
                                class="
                                  pointer
                                  text--regular
                                  form__action__text--danger
                                  ml-5
                                "
                              ></ion-icon
                            ></span>
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <label class="form__label mr-3">Label</label>
                          <input
                            type="text"
                            id="selectionOpt"
                            class="input form__input mr-3"
                          />
                          <label class="form__label mr-3">Score</label>
                          <input
                            type="text"
                            id="selectionScore"
                            class="input form__input mr-3"
                          />
                          <a
                            class="button button--grey"
                            @click="addToSelectionOption"
                            v-if="!isEditingOptions"
                          >
                            <ion-icon
                              name="add-outline"
                              class="text--medium"
                            ></ion-icon>
                          </a>
                          <a
                            class="button button--grey"
                            @click="updateSelectionOption"
                            v-if="isEditingOptions"
                          >
                            <ion-icon
                              name="checkmark-outline"
                              class="text--medium"
                            ></ion-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#description"
                    aria-expanded="false"
                    aria-controls="ccgeneral"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Description
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="description"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="form__item">
                        <label
                          class="
                            form__label
                            text--regular-alt text--bold text--black
                          "
                          >notes</label
                        >
                        <textarea
                          v-model="fieldForm.customFieldDescription"
                          name=""
                          cols="5"
                          rows="5"
                          class="input form__input"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>

              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="!isSaving"
                    @click="saveFieldForm"
                  >
                    save changes
                  </button>
                  <!-- loader button -->
                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      save-changes
                    "
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    v-if="!isSaving"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal modal__right"
      id="editFieldModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="modal"
                id="fieldEditFormCloseButton"
                class="modal__close modal__close--black"
                aria-label="Close"
                @click="clearFieldForm"
                :disabled="isSaving"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Edit Centres Custom Field
            </h5>
          </div>
          <div class="modal__body">
            <form class="form">
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Name</label>
                    <input
                      type="text"
                      v-model="fieldForm.customFieldName"
                      class="input form__input form__input--lg"
                      placeholder="Custom Field Name"
                      v-bind:class="{
                        'input-required': fieldFormErr.customFieldName,
                      }"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">ID</label>
                    <input
                      v-model="fieldForm.uniqueUserDefinedId"
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Custom Field ID"
                      v-bind:class="{
                        'input-required': fieldFormErr.uniqueUserDefinedId,
                      }"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Custom Field Set Type</label>
                    <input
                      v-model="fieldForm.customFieldType"
                      disabled
                      type="text"
                      class="input form__input form__input--lg"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Custom Field Set</label>
                    <select
                      class="select select--lg"
                      v-model="fieldForm.customFieldSetKey"
                      v-bind:class="{
                        'input-required': fieldFormErr.customFieldSetKey,
                      }"
                    >
                      <option value="null" disabled selected>
                        Select field set
                      </option>
                      <option
                        v-for="data in fieldFormFieldSets"
                        v-bind:key="data.customFieldSetKey"
                        v-bind:value="data.customFieldSetKey"
                      >
                        {{ data.customFieldSetName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Status</label>
                    <select
                      class="select select--lg"
                      v-model="fieldForm.customFieldStatus"
                      v-bind:class="{
                        'input-required': fieldFormErr.customFieldStatus,
                      }"
                    >
                      <option value="null" disabled selected>
                        Select field status
                      </option>
                      <option value="Activated">Activated</option>
                      <option value="Deactivated">Deactivated</option>
                    </select>
                  </div>
                </div>
              </div>

              <ul id="accordion" class="mb5">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#display"
                    aria-expanded="false"
                    aria-controls="ccgeneral"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Display
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="display" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="row">
                        <div
                          class="col-6"
                          v-bind:class="{
                            'col-12': fieldForm.dataType != 'Free text',
                            'col-6': fieldForm.dataType == 'Free text',
                          }"
                        >
                          <div class="form__item">
                            <label class="form__label">Type</label>
                            <select
                              class="select select--lg"
                              v-model="fieldForm.dataType"
                              v-bind:class="{
                                'input-required': fieldFormErr.dataType,
                              }"
                              disabled
                            >
                              <option value="null" disabled selected>
                                Select data type
                              </option>
                              <option value="Free text">Free Text</option>
                              <option value="Selection">Selection</option>
                              <option value="Number">Number</option>
                              <option value="Checkbox">Checkbox</option>
                              <option value="Date">Date</option>
                              <option value="Customer link">
                                Customer Link
                              </option>
                              <option value="Group link">Group Link</option>
                              <option value="User link">User Link</option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="col-6"
                          v-if="fieldForm.dataType == 'Free text'"
                        >
                          <div class="form__item">
                            <label class="form__label"
                              >Format<a
                                class="text--regular text--grey ml-2"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Custom Fields are standardized with a template using placeholders of '#' for numbers, '@' for letters or '$' for letter or number"
                                ><i class="bi bi-info-circle-fill"></i></a
                            ></label>
                            <input
                              v-model="fieldForm.validationPattern"
                              type="text"
                              class="input form__input form__input--lg"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="form__item">
                        <div>
                          <div class="mt-2 form__item form__item--label">
                            <label
                              for="users"
                              class="
                                has-checkbox
                                form__checkbox
                                text--dark text--capital
                              "
                              >Long Field
                              <input type="checkbox" id="users" class="form-" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        class="form__item"
                        v-if="fieldForm.dataType == 'Free text'"
                      >
                        <div class="mt-2 form__item form__item--label">
                          <label
                            for="val"
                            class="
                              has-checkbox
                              form__checkbox
                              text--dark text--capital
                            "
                            >Unique Value
                            <input
                              type="checkbox"
                              v-model="fieldForm.isUnique"
                              id="val"
                              class="form-"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div
                        class="selection-option"
                        v-if="fieldForm.dataType == 'Selection'"
                      >
                        <label class="form__label">Options</label>
                        <div class="input form__input mb-3 options-box">
                          <p
                            v-for="(lab, index) in fieldFormSelectionOptions"
                            :key="index"
                          >
                            {{ lab.label }}
                            <span v-show="lab.score">({{ lab.score }})</span>
                            <span>
                              <ion-icon
                                @click="editSelectionOption(index, lab)"
                                name="pencil-outline"
                                class="pointer text--regular ml-5"
                              ></ion-icon>
                              <ion-icon
                                @click="removeFromSelectionOption(index)"
                                name="trash-bin-outline"
                                class="
                                  pointer
                                  text--regular
                                  form__action__text--danger
                                  ml-5
                                "
                              ></ion-icon
                            ></span>
                          </p>
                        </div>
                        <div class="d-flex align-items-center">
                          <label class="form__label mr-3">Label</label>
                          <input
                            type="text"
                            id="selectionOptEdit"
                            class="input form__input mr-3"
                          />
                          <label class="form__label mr-3">Score</label>
                          <input
                            type="text"
                            id="selectionScoreEdit"
                            class="input form__input mr-3"
                          />
                          <a
                            class="button button--grey"
                            @click="addToSelectionOption"
                            v-if="!isEditingOptions"
                          >
                            <ion-icon
                              name="add-outline"
                              class="text--medium"
                            ></ion-icon>
                          </a>
                          <a
                            class="button button--grey"
                            @click="updateSelectionOption"
                            v-if="isEditingOptions"
                          >
                            <ion-icon
                              name="checkmark-outline"
                              class="text--medium"
                            ></ion-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#description"
                    aria-expanded="false"
                    aria-controls="ccgeneral"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Description
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="description"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="form__item">
                        <label
                          class="
                            form__label
                            text--regular-alt text--bold text--black
                          "
                          >notes</label
                        >
                        <textarea
                          v-model="fieldForm.customFieldDescription"
                          name=""
                          cols="5"
                          rows="5"
                          class="input form__input"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>

              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="!isSaving"
                    @click="updateFieldForm"
                  >
                    save changes
                  </button>
                  <!-- loader button -->
                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      save-changes
                    "
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    v-if="!isSaving"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="deleteFieldModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                id="deleteFieldCloseButton"
                data-dismiss="modal"
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Field
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to delete this Field ?
              </h5>
              <p class="form__label">
                when you delete this field. you cant reactivate it back on this
                table
              </p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="modal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                >
                  cancel
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="
                    button
                    form__button form__button--lg
                    w-25
                    modal__confirm__button modal__confirm__button--delete
                  "
                  v-if="isDeleting"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  v-if="!isDeleting"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="deleteFieldByKey(fieldDeleteKey)"
                >
                  delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import fieldSetService from "@/core/services/fieldset-service/fieldset.service";
export default {
  name: "field-centres",
  data: function () {
    return {
      content_header: {
        title: "Centres",
        subTitle: "",
      },
      isLoading: false,
      isDeleting: false,
      isSaving: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      fieldSetForm: {
        customFieldNotes: null,
        customFieldSetId: null,
        customFieldSetName: null,
        customFieldSetType: "Centres",
        usage: null,
        builtInType: "",
      },
      fieldSetFormErr: {
        customFieldSetId: false,
        customFieldSetName: false,
        customFieldSetType: false,
      },
      fieldSet: null,
      fieldSetKey: null,
      fieldSetDeleteKey: null,
      fieldSets: [],
      fieldFormFieldSets: [],
      fieldForm: {
        customFieldSetKey: null,
        dataType: null,
        customFieldDescription: null,
        uniqueUserDefinedId: null,
        isDefault: true,
        isRequired: false,
        customFieldName: null,
        customFieldStatus: null,
        customFieldType: "Centres",
        isUnique: false,
        validationPattern: null,
        valueLength: null,
        selectionsData: {
          selections: [],
        },
      },
      fieldFormErr: {
        customFieldName: false,
        uniqueUserDefinedId: false,
        customFieldSetKey: false,
        customFieldStatus: false,
        dataType: false,
      },
      fieldFormSelectionOptions: [],
      isEditingOptions: false,
      editOptionIndex: null,
      fieldFetchModel: {
        customFieldSetKey: null,
        pageIndex: 1,
        pageSize: 10,
      },
      fields: [],
      fieldDeleteKey: null,
      isEditingField: false,
    };
  },
  methods: {
    // Field set
    async getAllFieldSets() {
      this.isLoading = true;
      try {
        const res = await fieldSetService.getFieldSets({
          customFieldSetType: this.fieldForm.customFieldType,
        });
        this.fieldSets = res.data.data.items;
        this.fieldFormFieldSets = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getFieldsBySet(key) {
      this.isLoading = true;
      try {
        const res = await fieldSetService.getFields(key);
        this.fields = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getFieldByKey(key) {
      this.isLoading = true;
      try {
        const res = await fieldSetService.getField(key);
        this.fieldForm = res.data.data;
        this.fieldFormSelectionOptions = [];
        const options = res.data.data.customFieldSelections;
        options.forEach((value) => {
          const optionObj = {
            label: value.value,
            score: value.score ? value.score : 0,
            uniqueId: value.uniqueId,
            customFieldSelectionKey: value.customFieldSelectionKey,
          };

          this.fieldFormSelectionOptions.push(optionObj);
        });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    fieldSetSelectChange(event) {
      this.fieldFetchModel.customFieldSetKey = event.target.value;
      let fieldSetSearch = this.fieldSets.find(
        (x) => x.customFieldSetKey == event.target.value
      );
      this.fieldSet = fieldSetSearch;
      this.getFieldsBySet(this.fieldFetchModel);
    },
    editFieldSet(key) {
      let editInfo = this.fieldSets.find((x) => x.customFieldSetKey == key);
      this.fieldSetForm = editInfo;
    },
    clearFieldSetForm: function () {
      this.fieldSetForm = {
        customFieldNotes: null,
        customFieldSetId: null,
        customFieldSetName: null,
        customFieldSetType: null,
      };
    },
    clearFieldSetFormValidation() {
      this.fieldSetFormErr = {
        customFieldNotes: null,
        customFieldSetId: null,
        customFieldSetName: null,
        customFieldSetType: "Centres",
        usage: null,
        builtInType: "",
      };
    },
    validateFieldSetForm() {
      this.clearFieldSetFormValidation();
      if (
        this.fieldSetForm.customFieldSetId &&
        this.fieldSetForm.customFieldSetName 
        // && this.fieldSetForm.usage
      ) {
        return true;
      }

      if (!this.fieldSetForm.customFieldSetId) {
        this.fieldSetFormErr.customFieldSetId = true;
      }
      if (!this.fieldSetForm.customFieldSetName) {
        this.fieldSetFormErr.customFieldSetName = true;
      }
      // if (!this.fieldSetForm.usage) {
      //   this.fieldSetFormErr.usage = true;
      // }
    },
    saveFieldSetForm: async function (e) {
      e.preventDefault();
      if (this.validateFieldSetForm()) {
        this.isSaving = true;
        try {
          await fieldSetService.addFieldSet(this.fieldSetForm);
          this.getAllFieldSets();
          document.getElementById("fieldSetFormCloseButton").click();
          this.isSaving = false;
          this.clearFieldSetForm();
          this.alertSuccess("Field set created.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    saveFieldSetEditForm: async function (e) {
      e.preventDefault();
      if (this.validateFieldSetForm()) {
        this.isSaving = true;
        try {
          await fieldSetService.updateFieldSet(this.fieldSetForm);
          this.getAllFieldSets();
          document.getElementById("fieldSetEditFormCloseButton").click();
          this.isSaving = false;
          this.clearFieldSetForm();
          this.alertSuccess("Field set updated.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    openDeleteConfirmation(key, type) {
      if (type == "fieldSet") {
        this.fieldSetDeleteKey = key;
      } else if (type == "field") {
        this.fieldDeleteKey = key;
      }
    },
    async deleteFieldSetByKey(key) {
      this.isDeleting = true;
      try {
        await fieldSetService.deleteFieldSet(key);
        document.getElementById("deleteFieldSetCloseButton").click();
        this.getAllFieldSets();
        this.alertSuccess("Field set deleted");
        this.fieldSetDeleteKey = null;
        this.isDeleting = false;
      } catch (e) {
        this.isDeleting = false;
        const error = e.response.data.message || "Something went wrong";
        this.alertError(error);
      }
    },
    // Field
    addToSelectionOption: function (e) {
      e.preventDefault();
      let label, scoreText;
      if (!this.isEditingField) {
        label = document.getElementById("selectionOpt").value;
        scoreText = document.getElementById("selectionScore").value;
      } else {
        label = document.getElementById("selectionOptEdit").value;
        scoreText = document.getElementById("selectionScoreEdit").value;
      }
      const optionObj = {
        label,
        score: scoreText ? scoreText : 0,
        uniqueId: "",
      };
      const check = this.fieldFormSelectionOptions.findIndex(
        (x) => x.label == label
      );
      if (check === -1) {
        this.fieldFormSelectionOptions.push(optionObj);
      }
      document.getElementById("selectionOpt").value = "";
      document.getElementById("selectionScore").value = "";
      document.getElementById("selectionOptEdit").value = "";
      document.getElementById("selectionScoreEdit").value = "";
    },
    removeFromSelectionOption(index) {
      this.fieldFormSelectionOptions.splice(index, 1);
    },
    editSelectionOption(index, info) {
      this.isEditingOptions = true;
      this.editOptionIndex = index;
      if (!this.isEditingField) {
        document.getElementById("selectionOpt").value = info.label;
        if (info.score > 0) {
          document.getElementById("selectionScore").value = info.score;
        }
      } else {
        document.getElementById("selectionOptEdit").value = info.label;
        if (info.score > 0) {
          document.getElementById("selectionScoreEdit").value = info.score;
        }
      }
    },
    updateSelectionOption: function (e) {
      e.preventDefault();
      let label, scoreText;
      if (!this.isEditingField) {
        label = document.getElementById("selectionOpt").value;
        scoreText = document.getElementById("selectionScore").value;
      } else {
        label = document.getElementById("selectionOptEdit").value;
        scoreText = document.getElementById("selectionScoreEdit").value;
      }
      const optionObj = {
        label,
        score: scoreText ? scoreText : 0,
        uniqueId: "",
      };
      this.fieldFormSelectionOptions.splice(this.editOptionIndex, 1, optionObj);
      document.getElementById("selectionOpt").value = "";
      document.getElementById("selectionScore").value = "";
      document.getElementById("selectionOptEdit").value = "";
      document.getElementById("selectionScoreEdit").value = "";
      this.isEditingOptions = false;
    },
    clearFieldForm() {
      this.isEditingField = false;
      this.fieldForm = {
        customFieldSetKey: null,
        dataType: null,
        customFieldDescription: null,
        uniqueUserDefinedId: null,
        isDefault: true,
        isRequired: false,
        customFieldName: null,
        customFieldStatus: null,
        customFieldType: "Centres",
        isUnique: false,
        validationPattern: null,
        valueLength: null,
        selectionsData: {
          selections: [],
        },
      };
      this.fieldFormSelectionOptions = [];
      this.clearFieldFormValidation();
    },
    clearFieldFormValidation() {
      this.fieldFormErr = {
        customFieldName: false,
        uniqueUserDefinedId: false,
        customFieldSetKey: false,
        customFieldStatus: false,
        dataType: false,
      };
    },
    validateFieldForm() {
      this.clearFieldFormValidation();
      if (
        this.fieldForm.customFieldName &&
        this.fieldForm.uniqueUserDefinedId &&
        this.fieldForm.customFieldSetKey &&
        this.fieldForm.customFieldStatus &&
        this.fieldForm.dataType
      ) {
        return true;
      }

      for (const key in this.fieldFormErr) {
        if (Object.hasOwnProperty.call(this.fieldFormErr, key)) {
          if (
            this.fieldForm[key] == null ||
            this.fieldForm[key] == undefined ||
            this.fieldForm[key].trim() == ""
          ) {
            this.fieldFormErr[key] = true;
          }
        }
      }
      return false;
    },
    saveFieldForm: async function (e) {
      e.preventDefault();
      if (this.validateFieldForm()) {
        this.isSaving = true;
        try {
          if (this.fieldForm.dataType == "Selection") {
            this.fieldForm.selectionsData = { selections: [] };
            this.fieldForm.selectionsData.selections =
              this.fieldFormSelectionOptions;
          }
          await fieldSetService.addField(this.fieldForm);
          document.getElementById("fieldFormCloseButton").click();
          this.clearFieldForm();
          this.isSaving = false;
          this.getFieldsBySet(this.fieldFetchModel);
          this.alertSuccess("Field created.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    updateFieldForm: async function (e) {
      e.preventDefault();
      if (this.validateFieldForm()) {
        this.isSaving = true;
        try {
          if (this.fieldForm.dataType == "Selection") {
            this.fieldForm.selectionsData = { selections: [] };
            this.fieldForm.selectionsData.selections =
              this.fieldFormSelectionOptions;
            this.fieldForm.customFieldSelections =
              this.fieldFormSelectionOptions;
          }
          await fieldSetService.updateField(this.fieldForm);
          document.getElementById("fieldEditFormCloseButton").click();
          this.clearFieldForm();
          this.isSaving = false;
          this.getFieldsBySet(this.fieldFetchModel);
          this.alertSuccess("Field updated.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    editField(key) {
      this.isEditingField = true;
      let editInfo = this.fields.find((x) => x.customFieldKey == key);
      this.fieldForm = editInfo;
      this.getFieldByKey(key);
    },
    async deleteFieldByKey(key) {
      this.isDeleting = true;
      try {
        await fieldSetService.deleteField(key);
        document.getElementById("deleteFieldCloseButton").click();
        this.getFieldsBySet(this.fieldFetchModel);
        this.alertSuccess("Field deleted");
        this.fieldDeleteKey = null;
        this.isDeleting = false;
      } catch (e) {
        this.isDeleting = false;
        const error = e.response.data.message || "Something went wrong";
        this.alertError(error);
      }
    },
    // Alerts
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getAllFieldSets();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    userCanCreateCustomField() {
      const views = this.user.scopes.find(item => item.scopeName === Role.CustomField);
      const { permissionsList } = views;
      return permissionsList.CanCreateCustomField;
    },

    userCanEditCustomField() {
      const views = this.user.scopes.find(item => item.scopeName === Role.CustomField);
      const { permissionsList } = views;
      return permissionsList.CanEditCustomField;
    },

    userCanDeleteCustomField() {
      const views = this.user.scopes.find(item => item.scopeName === Role.CustomField);
      const { permissionsList } = views;
      return permissionsList.CanDeleteCustomField;
    },
  }
};
</script>
<style scoped>
.options-box {
  border: 2px solid rgba(128, 129, 145, 0.2) !important;
  max-height: 150px;
  overflow-y: auto;
}
.pointer {
  cursor: pointer;
}
</style>
